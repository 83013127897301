.home__hero-section {
  color: #fff;
}

.home__hero-row {
  align-items: center;
}

/* .row {
  display: flex;
  margin-right: -15px;
  margin-bottom: -15px;
  margin-left: -15px;
  flex-wrap: wrap;
  align-content: stretch;
} */

.col {
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.col1 {
  margin-bottom: 15px;
  flex: 1;

  flex-basis: 50%;
  border: #d8d3f5;
  border-style: solid;
  border-radius: 5px;
}

.home__hero-text-wrapper {
  max-width: fit-content;
  padding-top: 0;
  padding-bottom: 60px;
}

.home__hero-text-wrapper1 {
  max-width: 400px;
  padding-top: 0;
}


.top-line {
  color: #f00946;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.heading {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
  color: #7860ff;
  text-align: center;
}

.heading1 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 30px;
  line-height: 1.1;
  font-weight: 600;
  color: #7860ff;
  text-align: center;
}

.headingh6 {
  font-size: 11px;
  font-weight: 400;
  color: #7860ff;
  text-align: center;
}

.dark {
  color: #7860ff;
}

.darkBg {
  background-color: #f7f8fa;
}

.home__hero-subtitle {
  max-width: fit-content;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #7860ff;
  margin-left: 20px;
  margin-right: 20px;
}

.product__hero-subtitle {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 35px;
  font-size: 25px;
  line-height: 24px;
  color: #7860ff;
}

.home__hero-img-wrapper {
  max-width: 555px;
}

.home__hero-img {
  max-width: 95%;
  margin: 0 0 0 10px;
  padding-right: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 810px) {
  .home__hero-text-wrapper {
    padding-bottom: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .col {
    max-width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}