.footer-container {
  background-color: #7860ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
}

.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.footer-subscription>p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
  color: #fff;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
  width: 100%;
}

.footer-info {
  text-align: center;
}

.footer-info span{
  color: #fff;

}

.footer-info a{
  color: #a5b2bb;
  font-size: x-large;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items>h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 8px;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 32px;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

.social-icon-link {
  color: #fff;
  font-size: 26px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75px;
}

.contact-icons {
  display: contents;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-self: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

.company-logo {
  margin-right: 0.5rem;
}

.footer-address {
  margin-top: 10px;
  display: flex
}

.email-link:hover {
  color: #7860ff !important;
}

.first {
  width: 40%;
}

.second {
  width: 60%;
}

.third {
  width: 50%;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-items {
    align-items: center;
  }

  .footer-link-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .social-media-wrap {
    flex-direction: column;
  }

  .footer-address {
    display: inline
  }

  .first {
    width: 100%;
  }

  .second {
    width: 100%;
  }

  .third {
    width: 100%;
  }

  .social-icon-link {
    font-size: 16px;
  }
}