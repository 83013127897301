@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");


*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.product-card {
  box-shadow: 0 0 10px 5px #e1e5ee;
  border-radius: 0.2rem;
  max-width: 15rem;
  min-height: 15rem;
  flex-direction: column;
  display: flex;
}

.product-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card-body {
  margin: 1rem;
  flex-grow: 1;
}

.product-card-title {
  line-height: 2.5rem;
  margin-bottom: 0.5rem;
}