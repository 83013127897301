.star-rating {
    font-size: 2rem;
    color: #f5c518; /* Gold color for stars */
    text-align-last: center;
  }

  .star-rating1 {
    font-size: 1rem;
    color: #f5c518; /* Gold color for stars */
    text-align-last: center;
  }
  
  .star {
    margin: 0 2px;
  }
  
  .star.full {
    color: #f5c518; /* Gold color for full stars */
  }
  
  .star.half {
    color: #f5c518; /* Gold color for half stars */
  }
  
  .star.empty {
    color: #dcdcdc; /* Gray color for empty stars */
  }
  