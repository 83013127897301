.review-carousel {
    margin: 20px auto;
    width: 80%;
  }

  .review-carousel .slick-prev:before,
.review-carousel .slick-next:before,
.review-carousel .slick-prev:after,
.review-carousel .slick-next:after   {
  
  color: #8682ff;  /* Change to your desired color */
}
  
  .review-slide {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    text-align: center;
  }
  
  .review-author {
    display: -webkit-inline-box;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #111;
    font-size: 15px;
    width: 240px;
    padding: 10px;

    img{
        width: 60px;
    }

    strong{
        padding: 10px;
    }
  }
  
  .review-rating {
    font-size: 1.2rem;
    color: #f5c518; /* Gold color for rating */
  }
  
  .review-rating1 {
    font-size: 1rem;
    color: #f5c518; /* Gold color for rating */
  }

  .review-text {
    font-size: 1rem;
    color: #333;
  }
  